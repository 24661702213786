<template>
  <div id="input-data">
    <span class="headline3">Исходные данные</span>

    <MathJax :latex="visualInputData" :block="true" />

    <div id="actions">
      <Btn label="Изменить" @click="showDialog = true" />
      <Btn label="Решить" filled @click="getAnswer()" />
    </div>
  </div>

  <div id="answer" v-if="isDone">
    <span class="headline5">
      Шаги нахождения коэффициентов
      <MathJax latex="\(\vec{\beta}^*\)" />
    </span>
    <ol>
      <li>
        Добавляем в матрицу <MathJax latex="\(X\)" /> столбец фиктивных
        переменных <MathJax latex="\(x_{i0}\)" /> (1):
      </li>
      <MathJax :latex="'X =' + matrixToHtml(this.x)" block />

      <li>
        Находим <MathJax latex="\(X^T\)" /> (транспонированная матрица
        <MathJax latex="\(X\)" />):
      </li>
      <MathJax :latex="'X^T =' + matrixToHtml(XTranspose)" block />

      <li>
        Находим <MathJax latex="\(X^TX\)" /> (перемножение транспонированной
        матрицы <MathJax latex="\(X^T\)" /> с самой матрицей
        <MathJax latex="\(X\)" />):
      </li>
      <MathJax :latex="'X^TX =' + matrixToHtml(XTX)" block />

      <li>
        Находим <MathJax latex="\((X^T X)^{-1}\)" /> (обратная матрица для
        <MathJax latex="\(X^T X\)" />):
      </li>
      <MathJax :latex="'(X^T X)^{-1} =' + matrixToHtml(XTXInverse, 5)" block />

      <li>
        Находим <MathJax latex="\(X^T \vec{y}\)" /> (перемножение матрицы
        <MathJax latex="\(X^T\)" /> на вектор <MathJax latex="\(\vec{y}\)" />):
      </li>
      <MathJax :latex="'X^T \\vec{y} =' + matrixToHtml(XTY, 2)" block />

      <li>
        Находим <MathJax latex="\(\vec{\beta}^*\)" /> (перемножение обратной
        матрицы <MathJax latex="\((X^T X)^{-1}\)" /> на
        <MathJax latex="\(X^T \vec{y}\)" />):
      </li>
      <MathJax
        :latex="
          '\\vec{\\beta}^* = (X^T X)^{-1} X^T \\vec{y} =' +
          matrixToHtml(beta, 2)
        "
        block
      />

      <li>Определяем линейную функцию регрессии</li>
      <MathJax
        :latex="
          'y_i^* = ' +
          beta
            .map((b, i) => b[0].toFixed(2) + (i == 0 ? '' : `x_{i${i}}`))
            .reduce(
              (acc, val, i) =>
                acc + (i == 0 || val.includes('-') ? '' : ' + ') + val,
              ''
            )
        "
        block
      />
    </ol>

    <span class="headline5">
      Шаги нахождения коэффициента детерминации
      <MathJax latex="\(R\) " />:
    </span>
    <ol>
      <li>
        Рассчитываем прогнозные значения (перемножение матрицы
        <MathJax latex="\(X\)" /> на коэффициенты
        <MathJax latex="\(\vec{\beta}^*\)" />):
      </li>
      <MathJax
        :latex="
          'X \\vec{\\beta}^* = ' +
          matrixToHtml(x) +
          matrixToHtml(beta) +
          ' = ' +
          matrixToHtml(yHat)
        "
        block
      />

      <li>Рассчитываем среднее значение <MathJax latex="\(\bar{y}\)" />:</li>
      <MathJax :latex="`\\bar{y} = \\frac{1}{n} \\sum y_i = ${yMean}`" block />

      <li>Рассчитываем сумму квадратов отклонений:</li>
      <MathJax
        :latex="`\\text{SST} = \\sum_{i=1}^{n} (y_i - \\bar{y})^2 = ${SST}`"
        block
      />

      <li>Рассчитываем сумму квадратов регрессии:</li>
      <MathJax
        :latex="`\\text{SSR} = \\sum_{i=1}^{n} (\\hat{y}_i - \\bar{y})^2 = ${SSR}`"
        block
      />

      <li>Рассчитываем коэффициент:</li>
      <MathJax
        :latex="`R = \\frac{\\text{SSR}}{\\text{SST}} = ${SSR / SST}`"
        block
      />
    </ol>

    <span class="headline5">
      Шаги нахождения нормированных коэффициентов регрессии:
    </span>
    <ol>
      <li>
        Находим отклонения от случайного фактора по формуле
        <MathJax latex="\(E_i^* = y_i - y_i^*\)" />:
      </li>
      <MathJax
        v-for="(val, i) in residuals"
        :latex="`E_{${i + 1}}^* = ${y[i][0]} - ${yHat[i][0].toFixed(
          4
        )} = ${val.toFixed(4)}`"
        block
      />

      <li>Рассчитываем точечной несмещенной статистической оценкой:</li>
      <MathJax
        :latex="`S_{e}^2 = \\frac{\\sum{(E_i^*)^2}}{n - m - 1} = \\frac{\\text{${residuals
          .reduce((acc, val) => acc + val ** 2, 0)
          .toFixed(4)}}}{${y.length} - ${x[0].length - 1} - 1} = ${SE.toFixed(
          4
        )}`"
        block
      />
      Поскольку диагональные элементы матрицы
      <MathJax latex="\((X^T X)^{-1}\)" />
      соответственно равны
      <MathJax
        :latex="
          diagonalElements
            .map((val, i) => `b_{${i + 1}${i + 1}} = ${val.toFixed(4)}`)
            .join('; ')
        "
        block
      />
      то соответственно получим
      <MathJax
        v-for="(val, i) in diagonalElements"
        :latex="`S_{\\beta_${i}^*}^2 = S_{e}^2 b_{${i + 1}${
          i + 1
        }} = ${SE.toFixed(4)} · ${val.toFixed(4)} = ${(SEBeta[i] ** 2).toFixed(
          4
        )}, S_{\\beta_${i}^*} = ${SEBeta[i].toFixed(4)}`"
        block
      />

      <li>Рассчитываем среднеквадратичное отклонение:</li>
      <MathJax
        :latex="`S_y = \\sqrt{\\frac{\\sum (y_i - \\bar{y})^2}{n}} = \\sqrt{\\frac{${SST.toFixed(
          4
        )}}{${y.length}}} = ${SEY.toFixed(4)}`"
        block
      />

      <li>
        Рассчитываем нормированные коэффициенты регрессии по формуле
        <MathJax latex="\(a_i = \beta_i^* \frac{S_{\beta_i^*}}{S_y}\)" />:
      </li>
      <MathJax
        v-for="(val, i) in betaNormalized.slice(1)"
        :latex="`a_${i + 1} = \\beta_${i + 1}^* \\frac{S_{\\beta_${
          i + 1
        }^*}}{S_y} = ${beta[i + 1][0].toFixed(2)} \\frac{${SEBeta[
          i + 1
        ].toFixed(4)}}{${SE.toFixed(4)}} = ${val.toFixed(5)}`"
        block
      />
    </ol>

    <span class="headline5">Результаты:</span>

    <table>
      <tbody>
        <tr>
          <th><MathJax latex="\(\text{i}\)" /></th>
          <th><MathJax latex="\(y_i\)" /></th>
          <th v-for="(_, i) of x_input[0]">
            <MathJax :latex="`\\(x_{${i + 1}}\\)`" />
          </th>
          <th>
            <MathJax
              :latex="
                'y_i^* = ' +
                beta
                  .map((b, i) => b[0].toFixed(2) + (i == 0 ? '' : `x_{i${i}}`))
                  .reduce(
                    (acc, val, i) =>
                      acc + (i == 0 || val.includes('-') ? '' : ' + ') + val,
                    ''
                  )
              "
              block
            />
          </th>
          <th><MathJax latex="\(y_i - y_i^*\)" /></th>
          <th><MathJax latex="\((E_i^*)^2\)" /></th>
        </tr>

        <tr v-for="(val, i) in y">
          <td><MathJax :latex="i + 1" /></td>
          <td><MathJax :latex="val[0].toFixed(2)" /></td>
          <td v-for="row of x_input[i]">
            <MathJax :latex="row.toFixed(2)" />
          </td>
          <td><MathJax :latex="yHat[i][0].toFixed(2)" /></td>
          <td><MathJax :latex="residuals[i].toFixed(4)" /></td>
          <td><MathJax :latex="(residuals[i] ** 2).toFixed(4)" /></td>
        </tr>

        <tr>
          <td :colspan="3 + x[0].length"></td>
          <td colspan="2">
            <MathJax
              :latex="
                '\\(\\sum (E_i)^2 = ' +
                residuals.reduce((acc, val) => acc + val ** 2, 0).toFixed(4) +
                '\\)'
              "
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <Dialog
    v-model="showDialog"
    title="Ввод данных"
    description="Вводите значения матрицы через запятую, каждую строку разделяйте enter. Значения (y) можно ввести одной строкой разделяя запятой"
    permanent
  >
    <template v-slot:body>
      <textarea
        class="field"
        style="resize: vertical; height: 200px"
        v-model="x_dialog"
      ></textarea>
      <Field label="Вектор y" v-model="y_dialog" />
    </template>

    <template v-slot:actions>
      <Btn label="Закрыть" @click="showDialog = false" />
      <Btn label="Готово" filled @click="getInput()" />
    </template>
  </Dialog>
</template>

<script lang="ts">
// @ts-nocheck
import { defineComponent } from "vue";
import { MathJax } from "mathjax-vue3";
import Btn from "./components/Btn.vue";
import Field from "./components/Field.vue";
import Dialog from "./components/Dialog.vue";

// Функция для транспонирования матрицы
function transpose(matrix) {
  return matrix[0].map((_, i) => matrix.map((row) => row[i]));
}

// Функция для умножения двух матриц
function multiplyMatrices(matrix1, matrix2) {
  return matrix1.map((row) =>
    transpose(matrix2).map((column) =>
      row.reduce((sum, val, i) => sum + val * column[i], 0)
    )
  );
}

// Функция для вычисления обратной матрицы
function invertMatrix(matrix) {
  let size = matrix.length;
  let identityMatrix = Array(size)
    .fill()
    .map(() => Array(size).fill());
  for (let i = 0; i < size; ++i) {
    for (let j = 0; j < size; ++j) {
      identityMatrix[i][j] = i === j ? 1 : 0;
    }
  }
  for (let i = 0; i < size; ++i) {
    let max = i;
    for (let j = i + 1; j < size; ++j) {
      if (Math.abs(matrix[j][i]) > Math.abs(matrix[max][i])) {
        max = j;
      }
    }
    [matrix[i], matrix[max]] = [matrix[max], matrix[i]];
    [identityMatrix[i], identityMatrix[max]] = [
      identityMatrix[max],
      identityMatrix[i],
    ];
    for (let j = 0; j < size; ++j) {
      if (j !== i) {
        let ratio = matrix[j][i] / matrix[i][i];
        for (let k = i + 1; k < size; ++k) {
          matrix[j][k] -= ratio * matrix[i][k];
        }
        for (let k = 0; k < size; ++k) {
          identityMatrix[j][k] -= ratio * identityMatrix[i][k];
        }
      }
    }
  }
  for (let i = 0; i < size; ++i) {
    let a = matrix[i][i];
    for (let j = 0; j < size; ++j) {
      identityMatrix[i][j] /= a;
    }
  }
  return identityMatrix;
}

export default defineComponent({
  name: "App",

  components: {
    Btn,
    Field,
    Dialog,
    MathJax,
  },

  data: () => ({
    isDone: false,
    showDialog: false,

    x_dialog: "",
    y_dialog: "",

    x_input: [
      [29, 7, 0.09, 2.8],
      [34, 9, 0.1, 4.1],
      [43, 26, 0.08, 4],
      [33, 24, 0.12, 5],
      [53, 13, 0.14, 2.7],
      [26, 12, 0.13, 3.4],
      [32, 23, 0.1, 4.8],
      [51, 8, 0.12, 2.9],
      [43, 22, 0.15, 3.7],
      [29, 9, 0.02, 3.5],
      [37, 12, 0.08, 5],
      [49, 5, 0.14, 4.1],
      [57, 11, 0.11, 3.6],
      [46, 15, 0.06, 4.7],
      [29, 21, 0.15, 2.8],
    ],
    y: [
      5.73, 7.85, 12.53, 12.28, 7.47, 5.23, 12.16, 6.86, 11.02, 7.77, 10.62,
      7.4, 10.55, 12.3, 7.83,
    ].map((val) => [val]),

    XTranspose: [],
    XTX: [],
    XTXInverse: [],
    beta: [],
    yHat: [],
    yMean: 0,
    SST: 0,
    SSR: 0,
    residuals: [],
    SE: 0,
    diagonalElements: [],
    SEBeta: [],
    SEY: 0,
    betaNormalized: [],
  }),

  computed: {
    x(): number[][] {
      return this.x_input.map((row) => [1, ...row]);
    },
    visualInputData(): string {
      return (
        "X = " +
        this.matrixToHtml(this.x_input) +
        ", \\quad \\vec{y} = " +
        this.matrixToHtml(this.y)
      );
    },
  },

  methods: {
    matrixToHtml(matrix: number[][], round = 2) {
      return (
        "\\begin{pmatrix} " +
        matrix
          .map((row) => {
            return row.map((col) => col.toFixed(round)).join(" & ");
          })
          .join("\\\\ ") +
        " \\end{pmatrix}"
      );
    },

    getInput() {
      this.isDone = false;
      this.x_input = this.x_dialog
        .trim()
        .split("\n")
        .map((row) => row.split(",").map((val) => parseFloat(val.trim())));
      this.y = this.y_dialog
        .trim()
        .split(",")
        .map((val) => [parseFloat(val.trim())]);
      this.showDialog = false;
    },

    getAnswer() {
      const X = JSON.parse(JSON.stringify(this.x));
      const y = JSON.parse(JSON.stringify(this.y));

      // Шаг 1: Транспонирование X
      let XTranspose = transpose(X);
      this.XTranspose = JSON.parse(JSON.stringify(XTranspose));

      // Шаг 2: X^T * X
      let XTX = multiplyMatrices(XTranspose, X);
      this.XTX = JSON.parse(JSON.stringify(XTX));

      // Шаг 3: (X^T * X)^-1
      let XTXInverse = invertMatrix(XTX);
      this.XTXInverse = JSON.parse(JSON.stringify(XTXInverse));

      // Шаг 4: X^T * y
      let XTY = multiplyMatrices(XTranspose, y);
      this.XTY = JSON.parse(JSON.stringify(XTY));

      // Шаг 5: Рассчитать beta
      let beta = multiplyMatrices(XTXInverse, XTY);
      this.beta = JSON.parse(JSON.stringify(beta));

      // Рассчитываем прогнозные значения y
      let yHat = multiplyMatrices(X, beta);
      this.yHat = JSON.parse(JSON.stringify(yHat));
      // Рассчитываем среднее значение y
      let yMean = y.reduce((sum, val) => sum + val[0], 0) / y.length;
      this.yMean = JSON.parse(JSON.stringify(yMean));
      // Рассчитываем сумму квадратов отклонений (SST)
      let SST = y.reduce((sum, val) => sum + (val[0] - yMean) ** 2, 0);
      // Рассчитываем сумму квадратов регрессии (SSR)
      let SSR = yHat.reduce((sum, val) => sum + (val[0] - yMean) ** 2, 0);
      // Рассчитываем R^2
      let R = SSR / SST;
      this.SST = JSON.parse(JSON.stringify(SST));
      this.SSR = JSON.parse(JSON.stringify(SSR));

      // Остатки
      let residuals = y.map((val, i) => val[0] - yHat[i][0]);
      this.residuals = JSON.parse(JSON.stringify(residuals));

      // Стандартное отклонение остатков
      let SE =
        residuals.reduce((acc, val) => acc + val ** 2, 0) /
        (y.length - (X[0].length - 1) - 1);

      this.SE = JSON.parse(JSON.stringify(SE));

      // Диагональные элементы матрицы (X^T * X)^-1
      let diagonalElements = invertMatrix(
        multiplyMatrices(transpose(X), X)
      ).map((row, i) => row[i]);
      this.diagonalElements = JSON.parse(JSON.stringify(diagonalElements));

      // Стандартные ошибки коэффициентов
      let SEBeta = diagonalElements.map((val) => (SE * val) ** 0.5);
      this.SEBeta = JSON.parse(JSON.stringify(SEBeta));

      // Среднеквадратичное отклонение Y
      let SEY = Math.sqrt(
        y.reduce((sum, val) => sum + (val[0] - yMean) ** 2, 0) / y.length
      );
      this.SEY = JSON.parse(JSON.stringify(SEY));

      // Нормированные коэффициенты регрессии
      const betaNormalized = beta.map((val, i) => val * (SEBeta[i] / SEY));
      this.betaNormalized = JSON.parse(JSON.stringify(betaNormalized));

      this.isDone = true;
    },
  },

  mounted() {
    this.getAnswer();
  },
});
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: min(3vw, 18px) !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 0.2rem;
  background-color: rgba(28, 27, 31, 0.12);
}
::-webkit-scrollbar-track {
  margin: 0.25rem;
  border-radius: 0.2rem;
  background-color: rgba(28, 27, 31, 0.08);
}

#app {
  margin: 0 auto;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
}

.headline3 {
  font-size: min(5vw, 30px) !important;
  line-height: 1.2 !important;
}

.headline5 {
  font-size: min(3.45vw, 24px) !important;
  line-height: 1.2 !important;
}

#input-data {
  padding: 16px;
  background: #f7f2f9;
  border-radius: 20px;
  width: min(700px, 80vw);
  margin: 0 auto;
  margin-bottom: 48px;

  #actions {
    gap: 16px;
    display: flex;
    margin-top: 24px;
    justify-content: flex-end;
  }
}

#answer {
  padding: 16px;
  width: min(700px, 80vw);
  margin: 0 auto;

  mjx-container {
    overflow-y: hidden;
    overflow-x: auto;
  }

  table {
    overflow-x: auto;
    width: 100%;
    display: flex;
    flex-direction: column;

    th {
      padding: 0 8px;
      border: 1px solid #000;
      background: #f7f2f9;
    }

    td {
      padding: 0 8px;
      border: 1px solid #000;
      text-align: center;
    }
  }
}
</style>
